<script setup>

import {computed, onMounted, provide, ref, watch, onBeforeMount} from "vue";
import {useToast} from 'primevue/usetoast';
import {useRoute, useRouter} from 'vue-router';
import tabService from "@/services/TabService";
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";
import {useStore} from "vuex";
import TokenService from "@/services/TokenService";
import axios from "axios";

onBeforeMount(() => {
  axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const statusCode = error.response?.status;
        const message = error.response?.message || error.response?.data?.message;

        if (statusCode === 403 && message === 'User access is disabled by the admin') {
          return AuthenticationService.disable(store, router);
        }

        if(statusCode === 401) {
          router.push({path: '/login'});
        }

        return Promise.reject(error);
      }
  );
});

// general variables
const router = useRouter();
const route = useRoute();
const store = useStore();


const home = ref({
  "icon": 'pi pi-home',
  "route": '/',
});

const items = ref([
  {"label": '', "route": ''},
]);


function showToastMessage(severity, summary, detail) {
  console.log("showToastMessage", severity, summary, detail);
  toast.add({
    "severity": severity,
    "summary": summary,
    "detail": detail,
    "group": 'tr',
    "life": 3000
  });
}

provide("showToastMessage", showToastMessage);
provide("redirectUserToFirstAvailableTab", redirectUserToFirstAvailableTab);

const toast = useToast();

const menuVisible = ref(true);

const toggleMenu = () => {
  menuVisible.value = !menuVisible.value;
};

const isProfileDropdownVisible = ref(false);
const toggleProfileDropdownMenu = () => {
  isProfileDropdownVisible.value = !isProfileDropdownVisible.value;
};


const showDashboardTab = ref(false);
const showSchoolsTab = ref(false);
const showUsersTab = ref(false);
const showUploadTab = ref(false);
const showApiTab = ref(false);
const showDocsTab = ref(false);

const tabs = computed(() => [
  showDashboardTab.value ? {"label": "Dashboard", "icon": "pi pi-fw pi-home", "route": "/dashboard"} : null,
  showSchoolsTab.value ? {"label": "Schools", "icon": "pi pi-fw pi-building", "route": "/schools"} : null,
  showUsersTab.value ? {"label": "Users", "icon": "pi pi-fw pi-users", "route": "/users"} : null,
  showUploadTab.value ? {"label": "Upload", "icon": "pi pi-fw pi-upload", "route": "/upload"} : null,
  showApiTab.value ? {
    "label": "Eligibility Check",
    "icon": "pi pi-fw pi-verified",
    "route": "/eligibility-check"
  } : null,
  showDocsTab.value ? {"label": "Documentation", "icon": "pi pi-fw pi-file", "route": "/docs"} : null,
].filter(tab => tab !== null));

watch(() => route.path, (newRoutePath, oldRoutePath) => {
  console.log("watch newRoute.path", newRoutePath);
  console.log("watch oldRoute.path", oldRoutePath);
  const matchedTab = tabs.value.find(tab => tab.route === newRoutePath);
  console.log("newRoute.path", newRoutePath);
  if (matchedTab) {
    items.value = [
      {"label": matchedTab.label, "route": matchedTab.route}
    ];
  } else {
    const customRoutes = {
      '/profile': 'Profile',
      '/privacy-notice': 'Privacy Notice',
      '/terms-of-use': 'Terms of Use',
      '/cookie-notice': 'Cookie Notice'
    };

    const customLabel = customRoutes[newRoutePath];
    if (customLabel) {
      items.value = [{"label": customLabel, "route": newRoutePath}];
    } else {
      items.value = [{"label": 'Dashboard', "route": '/dashboard'}];
    }
  }
});

async function checkUserPermissionsForTabs() {
  const tabsPerms = await tabService.listUserTabPermissions(store, router);

  if (!tabsPerms) {
    return;
  }

  showDashboardTab.value = tabsPerms.includes('dashboard');
  showSchoolsTab.value = tabsPerms.includes('schools');
  showUsersTab.value = tabsPerms.includes('users');
  showUploadTab.value = tabsPerms.includes('upload');
  showApiTab.value = tabsPerms.includes('api');
  showDocsTab.value = tabsPerms.includes('docs');
}

async function setHomeRoute() {
  const config = TokenService.getConfig(router, store);
  if (!config) {
    return;
  }

  home.value.route = await tabService.getFirstAvailableTabUrl(config, router, store);
}

async function redirectUserToFirstAvailableTab() {
  const config = TokenService.getConfig(router, store);
  const path = await tabService.getFirstAvailableTabUrl(config, router, store);
  if(home.value.route !== path) {
    home.value.route = path;
  }

  const current = route.path;
  if (current === path) {
    return;
  }

  navigateTo(path)
}

function navigateTo(path) {
  router.push({path});
}

const userName = ref(store.getters.userName);

onBeforeMount(async () => {
  const originalRoute = route.path;
  console.log("originalRoute", originalRoute);
  await checkUserPermissionsForTabs();
  await setHomeRoute();
  if (originalRoute === '/') {
    await redirectUserToFirstAvailableTab();
  } else {
    //if link is to privacy-notice, terms-of-use, cookie-notice, then navigate to that route
    if (['/privacy-notice', '/terms-of-use', '/cookie-notice'].includes(originalRoute)) {
      navigateTo(originalRoute);
      return;
    }

    //if have permission to navigate to original route
    if (tabs.value.find(tab => tab.route === originalRoute)) {
      navigateTo(originalRoute);
    } else {
      //if not have permission to navigate to original route
      await redirectUserToFirstAvailableTab();
    }
  }
});

onMounted(async () => {
  //change breadcrumb items based on route
  const matchedTab = tabs.value.find(tab => tab.route === route.path);
  if (matchedTab) {
    items.value = [
      {"label": matchedTab.label, "route": matchedTab.route}
    ];
  } else {
    const customRoutes = {
      '/profile': 'Profile',
      '/privacy-notice': 'Privacy Notice',
      '/terms-of-use': 'Terms of Use',
      '/cookie-notice': 'Cookie Notice'
    };

    const customLabel = customRoutes[route.path];
    if (customLabel) {
      items.value = [{"label": customLabel, "route": route.path}];
    } else {
      items.value = [{"label": 'Dashboard', "route": '/dashboard'}];
    }
  }
});

function showProfile() {
  isProfileDropdownVisible.value = false;
  navigateTo('/profile');
}

async function logout() {
  const token = store.state.userToken;
  if (tokenService.isTokenExpired(token)) {
    return AuthenticationService.logoutByTimeout(router, store, true);
  }

  await AuthenticationService.callLogoutApi(store, router);
  isProfileDropdownVisible.value = false;
}
</script>

<template>
  <div class="flex min-h-[100vh]">
    <Toast position="top-right" group="tr"/>
    <div v-if="menuVisible"
         id="left-menu"
         class="h-auto overflow-y-auto min-h-[100vh] card flex flex-col w-[250px] shrink-0 bg-primaryBlue">
      <div class="px-10 py-4 cursor-pointer" @click.prevent="redirectUserToFirstAvailableTab">
        <img id="logo" alt="user header" src="/images/logo_white.svg"/>
      </div>

      <Menu :model="tabs" class="w-full mt-4 bg-transparent text-white !border-none">
        <template #item="{ item: tab, props }">
          <router-link v-if="tab.route" v-slot="{ href, navigate }" :to="tab.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate" class="py-5 px-7">
              <span :class="tab.icon"/>
              <span class="ml-2">{{ tab.label }}</span>
            </a>
          </router-link>
          <a v-else v-ripple :href="tab.url" :target="tab.target" v-bind="props.action">
            <span :class="tab.icon"/>
            <span class="ml-2">{{ tab.label }}</span>
          </a>
        </template>
      </Menu>
    </div>

    <div id="right-area" class="flex-grow bg-gray-100 px-16 flex flex-col">
      <div id="top-bar" class="py-1 flex justify-between items-center text-gray-500 -ml-3">
        <div class="flex">
          <Button icon="pi pi-bars" @click="toggleMenu" class="text-black bg-transparent border-none"/>
          <Breadcrumb :home="home" :model="items" class="text-black bg-transparent border-none font-medium">
            <template #item="{ item, props }">
              <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                  <span :class="[item.icon, 'text-color']"/>
                  <span class="text-primary font-semibold">{{ item.label }}</span>
                </a>
              </router-link>
              <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-surface-700 dark:text-surface-0">{{ item.label }}</span>
              </a>
            </template>
          </Breadcrumb>
        </div>

        <div class="relative">
          <div class="flex items-center cursor-pointer gap-4" @click="toggleProfileDropdownMenu">
            <Avatar icon="pi pi-user" class="bg-green-500 text-white" shape="circle"/>
            <span class="font-semibold text-blue-900">{{ userName }}</span>
            <i class="pi pi-chevron-down"></i>
          </div>

          <div v-if="isProfileDropdownVisible" class="absolute top-full mt-2 bg-white shadow-lg rounded-lg p-4 w-full">
            <ul>
              <li class="py-2 px-4 hover:bg-gray-100 cursor-pointer" @click="showProfile">Profile</li>
              <li class="py-2 px-4 hover:bg-gray-100 cursor-pointer" @click="logout">Logout</li>
            </ul>
          </div>
        </div>
      </div>

      <div id="content-area" class="flex-grow">
        <router-view :showDashboardTab="showDashboardTab"
                     :showSchoolsTab="showSchoolsTab"
                     :showUsersTab="showUsersTab"
                     :showUploadTab="showUploadTab"
                     :showApiTab="showApiTab"
                     :showDocsTab="showDocsTab"/>
      </div>

      <div id="footer-bar" class="flex justify-between items-center py-2 text-sm">
      <span id="left-footer">
        &copy; 2024 GuardMe International Insurance. All rights reserved.
      </span>
        <span id="right-footer" class="flex space-x-4 text-primaryBlue hover:text-black" >
        <router-link  :to="{ path: '/privacy-notice' }" target="_blank">Privacy Notice</router-link>
        <router-link  :to="{ path: '/terms-of-use' }"
                     target="_blank">Terms of Use</router-link>
        <router-link  :to="{ path: '/cookie-notice' }" target="_blank">Cookie Notice</router-link>
      </span>
      </div>
    </div>
  </div>

</template>

<style scoped>
</style>